import React, { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import config from '../../secret_config.json'

export const Invite = () => {
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [message, setMessage] = useState('')
  const { jwt } = useAuth()
  const { ls } = useLocale()

  const handleSend = async () => {
    try {
      const response = await fetch(`${config.apiFolder}gen_reg_link.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          email,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      setMessage(responseData.message)
    } catch (error) {
      setMessage('Failed to generate registration link: ' + error.message)
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setIsValidEmail(validateEmail(newEmail))
  }

  let sendButtonText = ls("Send link", "Link senden", "Отправить ссылку")

  return (
    <div style={{ marginBottom: '2rem', padding: '1rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <h2>{ls("Invite a New User", "Neuen Benutzer einladen", "Приглашение нового пользователя")}</h2>
      <p>
        {ls(
          `Generate a registration link for the New User. Please enter a valid email address of New User. After clicking „${sendButtonText}", the user will receive an email with a link to complete their registration.`,
          `Generieren Sie einen Registrierungslink für den neuen Benutzer. Bitte geben Sie eine gültige E-Mail-Adresse des neuen Benutzers ein. Nach dem Klick auf „${sendButtonText}" erhält der Benutzer eine E-Mail mit einem Link zur Vervollständigung der Registrierung.`,
          `Сгенерируйте ссылку для регистрации нового пользователя. Пожалуйста, введите корректный адрес электронной почты нового пользователя. После нажатия на «${sendButtonText}», пользователь получит письмо с ссылкой для завершения регистрации.`
        )}
      </p>
      <input
        type="text"
        placeholder={ls("email of user", "Email von Benutzer", "Емайл пользователя")}
        value={email}
        onChange={handleEmailChange}
      />
      <button onClick={handleSend} disabled={!isValidEmail}>{sendButtonText}</button>
      {message && <p>{message}</p>}
    </div>
  )
} 