import React, { useEffect, useState, useMemo } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { useNavigate } from 'react-router-dom'
import config from '../../secret_config.json'

export const GetUsers = () => {
  const [message, setMessage] = useState('')
  const [users, setUsers] = useState([])
  const [sortField, setSortField] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const { jwt, StatusToText, isSuperAdmin } = useAuth()
  const { ls } = useLocale()
  const navigate = useNavigate()

  useEffect(() => { handleGetUsers() }, [])

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const processedUsers = useMemo(() => {
    let result = [...users]
    
    if (sortField) {
      result.sort((a, b) => {
        let aValue = sortField === 'last_activity' 
          ? (a[sortField]?.formatted || '')
          : a[sortField]
        let bValue = sortField === 'last_activity'
          ? (b[sortField]?.formatted || '')
          : b[sortField]
        
        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1
        } else {
          return aValue < bValue ? 1 : -1
        }
      })
    }
    
    return result
  }, [users, sortField, sortDirection])

  const handleGetUsers = async () => {
    try {
      const response = await fetch(`${config.apiFolder}get_users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      if (responseData.status === 'success') {
        setUsers(responseData.users)
      } else {
        setMessage(responseData.message)
      }
    } catch (error) {
      setMessage('Failed to fetch users: ' + error.message)
    }
  }

  const handleEditUser = (userId) => {
    navigate(`/admin/${userId}`)
  }

  const formatLastActivity = (activity) => {
    if (!activity) return ls('Never', 'Nie', 'Никогда')

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        <div>{activity.formatted}</div>
        <small style={{ color: '#666' }}>
          {activity.device}, {activity.os}
          <br />
          {activity.browser} (v{activity.version})
        </small>
      </div>
    )
  }

  return (
    <div style={{ marginTop: '2rem', padding: '1rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <h2>{ls("Users List", "Benutzer Liste", "Список пользователей")}</h2>
      {message && <p>{message}</p>}
      <button onClick={handleGetUsers}>
        {ls("Get Users / Refresh", "Benutzer abrufen / Aktualisieren", "Получить пользователей / Обновить")}
      </button>
      {users.length > 0 && (
        <div style={{ overflowX: 'auto' }}>
          <table border="1" className="sortable-table">
            <thead>
              <tr>
                <th></th>
                {isSuperAdmin && (
                  <th onClick={() => handleSort('user_id')} style={{ cursor: 'pointer' }}>
                    ID
                    {sortField === 'user_id' && (
                      <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                    )}
                  </th>
                )}
                <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                  Name
                  {sortField === 'name' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('fullname')} style={{ cursor: 'pointer' }}>
                  Fullname
                  {sortField === 'fullname' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                  Email
                  {sortField === 'email' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                  Status
                  {sortField === 'status' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('last_activity')} style={{ cursor: 'pointer' }}>
                  {ls("Last Activity", "Letzte Aktivität", "Последняя активность")}
                  {sortField === 'last_activity' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {processedUsers.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    <button onClick={() => handleEditUser(user.user_id)}>
                      ✏️
                    </button>
                  </td>
                  {isSuperAdmin && <td>{user.user_id}</td>}
                  <td>{user.name}</td>
                  <td>{user.fullname}</td>
                  <td>{user.email}</td>
                  <td><StatusToText status={user.status} /></td>
                  <td>{formatLastActivity(user.last_activity)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
} 