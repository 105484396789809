import React, { useState } from 'react'
import config from '../../secret_config.json'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { useVersion } from '../../hooks/useVersion'

export const LoginComponent = () => {
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [login, setLogin] = useState('')
  const { login: loginFunction } = useAuth()
  const { version } = useVersion()
  const { ls } = useLocale()

  const handleLogin = async () => {
    try {
      const response = await fetch(`${config.apiFolder}login.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login, password, version }),
      }).catch(error => {
        // Обработка ошибок сети
        if (!navigator.onLine) {
          throw new Error(ls(
            'You are offline. Please check your internet connection.',
            'Sie sind offline. Bitte überprüfen Sie Ihre Internetverbindung.',
            'Вы не в сети. Пожалуйста, проверьте подключение к интернету.'
          ))
        }
        throw error
      })

      const responseText = await response.text()

      let responseData
      try {
        responseData = JSON.parse(responseText)
      } catch (jsonError) {
        throw new Error(ls('Invalid JSON response from server', 'Ungültige JSON-Antwort vom Server', 'Неверный JSON-ответ от сервера'))
      }

      if (!response.ok) {
        const serverMessage = responseData.message || `HTTP error! Status: ${response.status}`
        throw new Error(serverMessage)
      }

      if (responseData.jwt) {
        loginFunction(responseData.jwt)
      } else {
        throw new Error(ls('Login failed: JWT token not provided', 'Login fehlgeschlagen: JWT-Token nicht bereitgestellt', 'Вход не выполнен: JWT-токен не предоставлен'))
      }
    } catch (error) {
      setMessage(ls(
        `Login error: ${error.message}`, 
        `Login fehlgeschlagen: ${error.message}`, 
        `Вход не выполнен: ${error.message}`
      ))
    }
  }

  return (
    <div className="login-signup-forms">

      <div className="login-form">
        <h2>{ls("Login", "Anmelden", "Войти")}</h2>
        <input
          type="text"
          placeholder={ls("Username or Email", "Benutzername oder E-Mail", "Имя пользователя или емейл")}
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <input
          type="password"
          placeholder={ls("Password", "Passwort", "Пароль")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="button-login" onClick={handleLogin}>
          {ls("Login", "Anmelden", "Войти")}
        </button>
      </div>

      {message && <p>{message}</p>}
    </div>
  )
}
