import React, { useRef } from "react"
import { mod, dateParse, solve, getAge, strToDate, checkTripletWithStyles, solve2 } from "../../utils/utils"
import { localize } from "../../../../contexts/LocaleContext"
import { useLocale } from "../../../../hooks/useLocale"
import { addToSerializeTabFirst, addToSerializeTabSecond, cleanSerializeTabFirst, cleanSerializeTabSecond } from "../../utils/storage"
import { spanWithHint, UndefinedComponemt } from "../Components"
import { headerC2, setReport, getReport, rowC2, rowC4_span, rowC4S1_span } from "./Common"
import { useDate } from "../../hooks/useDate"
import "../../../../styles/Table.less"


export const MainDataThirdTable = localize(({ texts, first, second }) => {
  let { locale, ls } = useLocale()
  let { date1, date2 } = useDate()
  // let ref = useRef()

  // let date = first ? date1 : second ? date2 : undefined
  // if (!date) return <UndefinedComponemt name="MainDataTable" />

  // if (!ref.current) ref.current = {}

  // if (date !== ref.current.date) {
  //   first ? cleanSerializeTabFirst() : cleanSerializeTabSecond()
  //   ref.current.date = date
  // }

  // if (!ref.current.s) {
  //   setReport(first ? addToSerializeTabFirst : addToSerializeTabSecond)
  //   ref.current.s = getReport()
  // }
  // else setReport(ref.current.s)

  let { d: dd, m: mm, y: yy } = dateParse(date1)
  let { d: dd2, m: mm2, y: yy2 } = dateParse(date2)
  let { a, b, c, d, a1, b1, c1, d1, a3, b3, a2, b2, a4, b4, c2, d2, e, sky, earth, m, f } = solve2(solve(dd, mm, yy), solve(dd2, mm2, yy2))

  let summSE = mod(sky + earth)
  let [s_sky, s_earth, s_summSE] = checkTripletWithStyles(sky, earth, summSE, { withHole: true })

  let summMF = mod(m + f)
  let [s_m, s_f, s_summMF] = checkTripletWithStyles(m, f, summMF, { withHole: true })

  let spirit = mod(summSE + summMF)

  let s = getReport()

  return (
    <table >
      <tbody>
        {headerC2(ls("Destiny", "Bestimmung", "Предназначение"), `${date1} + ${date2}`)}
        {/* {rowC2(texts.age, getAge(strToDate(date), locale))} */}
        <tr>
          <td className="align-right">{getReport()(texts.sky)}</td>
          <td style={s_sky.style} className="CellWithComment" >{s(s_sky)}{spanWithHint(s_sky, false)}</td>
          <td rowSpan="2" className="CellWithComment" >
            <div className="braces">
              <div className="curly" style={s_summSE.style}>{s(s_summSE)}</div>
            </div>
            {spanWithHint(s_summSE, false)}
          </td>
          <td className="align-right">{s(texts.male)}</td>
          <td style={s_m.style} className="CellWithComment">{s(s_m)}{spanWithHint(s_m, false)}</td>
          <td rowSpan="2" className="CellWithComment">
            <div className="braces">
              <div className="curly" style={s_summMF.style}>{s(s_summMF)}</div>
            </div>
            {spanWithHint(s_summMF, false)}
          </td>
        </tr>
        <tr>
          <td className="align-right">{s(texts.earth)}</td>
          <td style={s_earth.style} className="CellWithComment">{s(s_earth)}{spanWithHint(s_earth, false)}</td>
          <td className="align-right">{s(texts.female)}</td>
          <td style={s_f.style} className="CellWithComment">{s(s_f)}{spanWithHint(s_f, false)}</td>
        </tr>
        {rowC4_span(texts.spiritual, spirit, texts.planetary, mod(spirit + summMF))}
        {/* {rowC2(texts.power, genusPower)} */}
        {/* {rowC4S1_span(texts.innerStrength, genusPower, e, false)} */}
        {/* {rowC4S1_span(texts.maleKey, a1, c1, false)} */}
        {/* {rowC4S1_span(texts.femaleKey, b1, d1, false)} */}
      </tbody>
    </table >
  )
})

MainDataThirdTable.defaultProps = {
  texts: {
    birthday: "Birthday",
    age: "Age",
    spiritual: "Spiritual",
    planetary: "Planetary",
    power: "Power of family",
    innerStrength: "Inner strength",
    maleKey: "Male key",
    femaleKey: "Female key",

    earth: "Earth :",
    sky: "Sky :",
    male: "m :",
    female: "f :",
  },
  "texts-de": {
    birthday: "Geburtstag",
    age: "Alter",
    spiritual: "Spirituell",
    planetary: "Planetarisch",
    power: "Kraft der Ahnen",
    innerStrength: "Innere Stärke",
    maleKey: "Männlicher Schlüssel",
    femaleKey: "Weiblicher Schlüssel",

    earth: "Erde :",
    sky: "Himmel :",
    male: "m :",
    female: "w :",
  },
  "texts-ru": {
    birthday: "День рожденья",
    age: "Возраст",
    spiritual: "Духовное",
    planetary: "Планетарное",
    power: "Сила рода",
    innerStrength: "Внутренняя сила",
    maleKey: "Мужской ключ",
    femaleKey: "Женский ключ",

    earth: "Земля :",
    sky: "Небо :",
    male: "М :",
    female: "Ж :",
  }
}