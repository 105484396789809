import React, { useState } from 'react'
import config from '../../secret_config.json'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { UserProfileEdit } from './UserProfileEdit'

export const UserMenu = () => {
  const [message, setMessage] = useState('')
  const { name, fullname, logout, email, isBlocked, isAdmin, isSuperAdmin, jwt, login: loginFunction } = useAuth()
  const { ls } = useLocale()

  const handleSaveProfile = async (newFullname) => {
    try {
      const response = await fetch(`${config.apiFolder}update_profile.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          fullname: newFullname
        }),
      })

      if (!response.ok) {
        throw new Error(ls(
          'Failed to update profile',
          'Fehler beim Aktualisieren des Profils',
          'Не удалось обновить профиль'
        ))
      }

      const data = await response.json()
      if (data.status === 'success') {
        // Update JWT with new data
        if (data.jwt) {
          loginFunction(data.jwt)
        }
      } else {
        throw new Error(data.message)
      }
    } catch (error) {
      throw error
    }
  }

  const handleLogout = () => {
    const confirmed = window.confirm(ls(
      "Warning: If you are offline, the site may stop working after logout until you reconnect to the internet. Do you want to continue?",
      "Warnung: Wenn Sie offline sind, funktioniert die Website nach dem Abmelden möglicherweise erst wieder, wenn Sie eine Internetverbindung haben. Möchten Sie fortfahren?",
      "Внимание: Если вы находитесь в офлайн режиме, сайт может перестать работать после выхода до восстановления интернет-соединения. Хотите продолжить?"
    ))

    if (confirmed) {
      logout()
      setMessage(ls('Logged out successfully.', 'Erfolgreich abgemeldet.', 'Успешно вышли.'))
    }
  }

  const handleResetSW = async () => {
    const confirmed = window.confirm(ls(
      "This will clear the site's cache and may help if:\n\n" +
      "- The site is not updating to a new version\n" +
      "- You're experiencing display issues\n\n" +
      "Your login and data will be preserved.\n\n" +
      "Warning: If you are offline, the site may stop working until you reconnect to the internet.\n\n" +
      "Do you want to continue?",

      "Dies löscht den Cache der Website und kann helfen, wenn:\n\n" +
      "- Die Website sich nicht auf eine neue Version aktualisiert\n" +
      "- Anzeigeprobleme auftreten\n\n" +
      "Ihre Anmeldung und Daten bleiben erhalten.\n\n" +
      "Warnung: Wenn Sie offline sind, funktioniert die Website möglicherweise erst wieder, wenn Sie eine Internetverbindung haben.\n\n" +
      "Möchten Sie fortfahren?",

      "Это очистит кэш сайта и может помочь, если:\n\n" +
      "- Сайт не обновляется до новой версии\n" +
      "- Есть проблемы с отображением\n\n" +
      "Ваш логин и данные сохранятся.\n\n" +
      "Внимание: Если вы находитесь в офлайн режиме, сайт может перестать работать до восстановления интернет-соединения.\n\n" +
      "Хотите продолжить?"
    ))

    if (confirmed) {
      try {
        // Clear all caches
        const keys = await caches.keys()
        await Promise.all(keys.map(key => caches.delete(key)))

        // Unregister all service workers
        const registrations = await navigator.serviceWorker.getRegistrations()
        await Promise.all(registrations.map(reg => reg.unregister()))

        setMessage(ls('Cache cleared and Service Worker removed. Reloading...', 'Cache geleert und Service Worker entfernt. Neuladen...', 'Кэш очищен и Service Worker удален. Перезагрузка...'))
        
        // Small delay to allow user to see the message
        setTimeout(() => {
          window.location.reload(true)
        }, 1000)
      } catch (error) {
        console.error('Error resetting SW:', error)
        setMessage(ls('Error: ' + error.message, 'Fehler: ' + error.message, 'Ошибка: ' + error.message))
      }
    }
  }

  return (
    <div className="login-menu-content">
      <p>{ls("User", "Benutzer", "Пользователь")}:&nbsp;{name}</p>
      {fullname && <p>{ls("Fullname", "Vollname", <span>Полное&nbsp;имя</span>)}:&nbsp;{fullname.replace(/\s+/g, '\u00A0')}</p>}
      <p>{ls("Email", <span>E&#8209;Mail</span>, "Емейл")}:&nbsp;{email}</p>
      {isBlocked && <p>{ls(
        <span style={{ color: "#ffadad" }}>Account is blocked</span>,
        <span style={{ color: "#ffadad" }}>Konto ist gesperrt</span>,
        <span style={{ color: "#ffadad" }}>Аккаунт заблокирован</span>)}</p>}
      {isAdmin && !isSuperAdmin && (
        <p>{ls("Status", "Status", "Статус")}:&nbsp;{ls("admin", "Administrator", "админ")}</p>
      )}
      {isSuperAdmin && (
        <p>{ls("Status", "Status", "Статус")}:&nbsp;{ls("super\u2011admin", "Super\u2011Administrator", "супер\u2011админ")}</p>
      )}
      
      <UserProfileEdit
        currentFullname={fullname}
        onSave={handleSaveProfile}
      />
      
      <button className="button-login" onClick={handleLogout}>
        {ls("Logout", "Abmelden", "Выйти")}
      </button>
      <button 
        className="button-clear-cache" 
        onClick={handleResetSW}
        title={ls(
          "Clear cache and remove Service Worker",
          "Cache leeren und Service Worker entfernen",
          "Очистить кэш и удалить Service Worker"
        )}
      >
        {ls(
          "Clear Cache",
          "Cache leeren",
          "Очистить кэш"
        )}
      </button>
      {message && <p>{message}</p>}
    </div>
  )
}