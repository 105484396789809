import React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { LoginComponent } from '../common/LoginComponent'
import { useLocale } from '../../hooks/useLocale'
import { Calculator } from '../../features/calc/Calculator'

export const HomePage = () => {
  const { isLogin, isBlocked, isSuperAdmin } = useAuth()
  const { ls } = useLocale()

  return (
    <div>
      {isLogin && !isBlocked && <Calculator />}
      {!isLogin && <LoginComponent />}
      {isLogin && isBlocked && <p>
        {ls(
          "Your account is blocked. Please contact your administrator.",
          "Ihr Konto ist gesperrt. Bitte kontaktieren Sie Ihren Administrator.",
          "Ваш аккаунт заблокирован. Пожалуйста, свяжитесь с администратором.")}
      </p>}
    </div>
  )
} 