/**
 * Application Update Manager
 * Handles Service Worker interaction and version updates
 */

/**
 * Checks for updates through Service Worker
 */
export const checkUpdates = async () => {
  if (!('serviceWorker' in navigator)) return

  const registration = await navigator.serviceWorker.ready
  if (registration.active) {
    console.log('Checking for updates...')
    registration.active.postMessage({ type: 'CHECK_VERSION' })
  }
}

/**
 * Sets up Service Worker event handlers
 * @param {Function} onUpdateAvailable - Callback called when update is available
 * @returns {Function} Cleanup function
 */
export const setupUpdateHandlers = (onUpdateAvailable) => {
  if (!('serviceWorker' in navigator)) return () => {}

  // Service Worker message handler
  const messageHandler = (event) => {
    if (event.data.type === 'CHECK_VERSION' && event.data.newVersion) {
      console.log('Update available:', event.data.newVersion, 'needs reload:', event.data.needsReload)
      onUpdateAvailable(event.data.newVersion, event.data.needsReload)
    }
  }

  // Subscribe to messages
  navigator.serviceWorker.addEventListener('message', messageHandler)
  
  // Subscribe to Service Worker changes
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('Controller changed, checking version...')
    checkUpdates()
  })

  // Start periodic update checks
  const checkInterval = setInterval(checkUpdates, 5 * 60 * 1000) // 5 minutes

  // Initial check
  checkUpdates()

  // Return cleanup function
  return () => {
    clearInterval(checkInterval)
    navigator.serviceWorker.removeEventListener('message', messageHandler)
  }
} 