import React, { useState, useEffect } from 'react'
import { useLocale } from '../../hooks/useLocale'
import { Modal } from './Modal'
import '../../styles/Modal.less'
import { useModal } from '../../hooks/useModal'

export const UserProfileEdit = ({ currentFullname, onSave, onOpenChange }) => {
  const { isOpen, open, close } = useModal()
  const [newFullname, setNewFullname] = useState(currentFullname || '')
  const [message, setMessage] = useState('')
  const { ls } = useLocale()

  useEffect(() => {
    onOpenChange?.(isOpen)
  }, [isOpen, onOpenChange])

  useEffect(() => {
    if (isOpen) {
      setMessage('')
    }
  }, [isOpen])

  const handleSave = async () => {
    if (!newFullname.trim()) {
      setMessage(ls(
        'Full name cannot be empty',
        'Der vollständige Name darf nicht leer sein',
        'Полное имя не может быть пустым'
      ))
      return
    }

    try {
      await onSave(newFullname)
      setMessage(ls(
        'Profile updated successfully',
        'Profil erfolgreich aktualisiert',
        'Профиль успешно обновлен'
      ))
      setTimeout(close, 1500)
    } catch (error) {
      setMessage(error.message)
    }
  }

  return (
    <>
      <button className='button-login' onClick={open}>
        {ls("Edit profile", "Profil bearbeiten", "Редактировать профиль")}
      </button>
      <Modal
        isOpen={isOpen}
        onClose={close}
        title={ls("Edit Profile", "Profil bearbeiten", "Редактировать профиль")}
      >
        <p className="modal-form-description">
          {ls(
            "Please enter your new full name below",
            "Bitte geben Sie unten Ihren neuen vollständigen Namen ein",
            "Пожалуйста, введите ваше новое полное имя ниже"
          )}
        </p>
        
        <div className="modal-form-input-container">
          <label>
            {ls("Full Name", "Vollständiger Name", "Полное имя")}:
            <input
              type="text"
              value={newFullname}
              onChange={(e) => setNewFullname(e.target.value)}
              placeholder={ls(
                "Enter your full name",
                "Geben Sie Ihren vollständigen Namen ein",
                "Введите ваше полное имя"
              )}
            />
          </label>
        </div>

        {message && (
          <div className={`modal-form-message ${message.includes('success') ? 'success' : 'error'}`}>
            {message}
          </div>
        )}

        <div className="modal-form-buttons">
          <button className="button-clear-cache" onClick={close}>
            {ls("Cancel", "Abbrechen", "Отмена")}
          </button>
          <button className="button-login" onClick={handleSave}>
            {ls("Save", "Speichern", "Сохранить")}
          </button>
        </div>
      </Modal>
    </>
  )
} 