import React from 'react'
import { useVersion } from '../../hooks/useVersion'
import '../../styles/Footer.less'

export const Footer = () => {
  const { version, formattedDate } = useVersion()

  return (
    <footer>
      <div className='footer-text'>
        &copy; {new Date().getFullYear()} created by MozgOFF
        {` v${version} (${formattedDate})`}
      </div>
    </footer>
  )
}