import React, { useContext, memo } from 'react'

export const localeContext = React.createContext({ locale: "en", setLocale: undefined, ls: undefined })
localeContext.displayName = 'LocaleContext'
export const LocaleProvider = localeContext.Provider

function setDisplayName(prefix, source, obj) {
  obj.displayName = `${prefix}(${source.displayName || source.name})`
  return obj
}

function applyLocProps(props, localizedProps, locale) {

  const result = { ...props }

  for (var i in localizedProps) {

    var prop = localizedProps[i]
    var idx = prop.indexOf('-')
    var lang = prop.slice(idx + 1, prop.length)
    if (lang === locale) {
      const name = prop.slice(0, idx)
      result[name] = result[prop]
    }

    delete result[prop]
  }

  return result
}

function findLocalizedProps(props) {
  return Object.keys(props).filter(prop => !prop.startsWith("bind-") && prop.indexOf('-') > 0)
}

export function localize(Target) {

  let n = Target.displayName || Target.name
  if (n && n.includes('L('))
    return Target

  if (Target.prototype === undefined) {
    return setDisplayName('L', Target, props => Target(useLocalizedProps(props)))
  }

  return setDisplayName('M', Target, memo(setDisplayName('L', Target, props => <Target {...useLocalizedProps(props)} />)))
}

function useLocalizedProps(props) {
  const { locale } = useContext(localeContext)
  const localizedProps = findLocalizedProps(props)
  return localizedProps.length > 0 ? applyLocProps(props, localizedProps, locale) : props
}