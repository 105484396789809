import React, { createContext, useContext, useState } from "react"
import { useAuth } from "../../../hooks/useAuth"

const DateContext = createContext()

export const DateProvider = ({ children }) => {
  const { isFullUser } = useAuth()
  const isValidDate = (date) => /^\d{2}\.\d{2}\.\d{4}$/.test(date)

  const [date1, setDate1State] = useState(() => {
    const storedDate = localStorage.getItem("defDate") || "01.01.2001"
    return isValidDate(storedDate) ? storedDate : "01.01.2001"
  })

  const [date2, setDate2State] = useState(() => {
    const storedDate = localStorage.getItem("defDate2") || "02.02.2002"
    return isValidDate(storedDate) ? storedDate : "02.02.2002"
  })

  const [name1, setName1State] = useState(() => {
    return localStorage.getItem("defName") || ""
  })

  const [name2, setName2State] = useState(() => {
    return localStorage.getItem("defName2") || ""
  })

  const [single, setSingleState] = useState(() => {
    if (!isFullUser) return true
    try {
      return JSON.parse(localStorage.getItem("singleDate") || "true")
    } catch {
      return true
    }
  })


  const setDate1 = (text) => {
    if (isValidDate(text)) {
      localStorage.setItem("defDate", text)
      setDate1State(text)
    }
  }

  const setDate2 = (text) => {
    if (isValidDate(text)) {
      localStorage.setItem("defDate2", text)
      setDate2State(text)
    }
  }

  const setSingle = (value) => {
    if (!isFullUser) value = true
    const newValue = JSON.stringify(value)
    localStorage.setItem("singleDate", newValue)
    setSingleState(value)
  }

  const swapDate = () => {
    setDate1(date2)
    setDate2(date1)
    setName1(name2)
    setName2(name1)
  }

  const setName1 = (text) => {
    localStorage.setItem("defName", text)
    setName1State(text)
  }

  const setName2 = (text) => {
    localStorage.setItem("defName2", text)
    setName2State(text)
  }

  return (
    <DateContext.Provider value={{
      date1, date2, setDate1, setDate2,
      name1, name2, setName1, setName2,
      isSingle: single, isDouble: !single,
      setSingle, setDouble: (value) => setSingle(!value),
      swapDate,
      isValidDate
    }}>
      {children}
    </DateContext.Provider>
  )
}

export const useDate = () => useContext(DateContext)
