import React from 'react'
import { Link } from 'react-router-dom'
import { useLocale } from "../../hooks/useLocale"
import '../../styles/Header.less'
import { UserMenuButton } from '../common/UserMenuButton'
import { useAuth } from '../../hooks/useAuth'
import { UpdateBanner } from '../../updater/UpdateBanner'

export const Header = () => {
  let { locale, setLocale, ls } = useLocale()
  let { isAdmin } = useAuth()

  return (
    <header className='header-text'>
      <div className="header-content">
        <Link to="/">
          <button className='header-button'>
            <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
          </button>
        </Link>
        <nav className="menu">
          <Link to="/about">
            <button>{ls("About", "Über", "О сайте")}</button>
          </Link>
          <Link to="/contacts">
            <button>{ls("Contacts", "Impressum", "Контакты")}</button>
          </Link>
          {isAdmin && <Link to="/admin">
            <button>{ls("Admin", "Admin", "Админ")}</button>
          </Link>}
        </nav>
        <div className="menu">
          <div className="language-selector">
            <select className='lang-select' name="languages" id="lang" value={locale} onChange={(e) => {
              if (__DEBUG__) console.log(e.target.value)
              setLocale(e.target.value)
            }}>
              <option value="en">en</option>
              <option value="de">de</option>
              <option value="ru">ru</option>
            </select>
          </div>
          <UserMenuButton />
        </div>
      </div>
      <UpdateBanner />
    </header>
  )
}