import React, { useRef } from "react"
import { mod, dateParse, solve } from "../../utils/utils"
import { localize } from "../../../../contexts/LocaleContext"
import { addToSerializeTabFirst, addToSerializeTabSecond, cleanSerializeTabFirst, cleanSerializeTabSecond } from "../../utils/storage"
import "../../../../styles/Table.less"
import { getReport, headerC4, rowC4, rowC4S1, setReport } from "./Common"
import { UndefinedComponemt } from  "../Components"
import { useDate } from "../../hooks/useDate"

export const ChakraDataTable = localize(({ texts, first, second }) => {
  let ref = useRef()
  let { date1, date2 } = useDate()

  let date = first ? date1 : second ? date2 : undefined
  if (!date) return <UndefinedComponemt name="ChakraDataTable" />

  if (!ref.current) ref.current = {}

  if (date !== ref.current.date) {
    first ? cleanSerializeTabFirst() : cleanSerializeTabSecond()
    ref.current.date = date
  }

  if (!ref.current.s) {
    setReport(first ? addToSerializeTabFirst : addToSerializeTabSecond)
    ref.current.s = getReport()
  }
  else setReport(ref.current.s)

  let { d: dd, m: mm, y: yy } = dateParse(date)
  let { a, b, c, d, a1, b1, c1, d1, a3, b3, a2, b2, a4, b4, c2, d2, e, sky, earth, m, f } = solve(dd, mm, yy)

  return (
    <table >
      <tbody>
        {headerC4(texts.chakras, texts.physics, texts.energy, texts.emotions)}
        {rowC4S1(texts.sahasrara, a, b, false, { backgroundColor: "darkviolet", color: "white" })}
        {rowC4S1(texts.ajna, a3, b3, false, { backgroundColor: "cornflowerblue", color: "white" })}
        {rowC4S1(texts.vishuddha, a2, b2, false, { backgroundColor: "deepskyblue", color: "white" })}
        {rowC4S1(texts.anahata, a4, b4, false, { backgroundColor: "forestgreen", color: "white" })}
        {rowC4S1(texts.manipura, e, e, false, { backgroundColor: "yellow", color: "black" })}
        {rowC4S1(texts.svadhisthana, c2, d2, false, { backgroundColor: "orange", color: "white" })}
        {rowC4S1(texts.muladhara, c, d, false, { backgroundColor: "red", color: "white" })}
        {rowC4(texts.total,
          mod(a + a3 + a2 + a4 + e + c2 + c), mod(b + b3 + b2 + b4 + e + d2 + d),
          mod(mod(a + b) + mod(a3 + b3) + mod(a2 + b2) + mod(a4 + b4) + mod(e + e) + mod(c2 + d2) + mod(c + d)), false)}
      </tbody>
    </table >)
})

ChakraDataTable.defaultProps = {
  texts: {
    chakras: "Chakras",
    physics: "Physics",
    energy: "Energy",
    emotions: "Emotions",

    sahasrara: "7. Sahasrara",
    ajna: "6. Ajna",
    vishuddha: "5. Vishuddha",
    anahata: "4. Anahata",
    manipura: "3. Manipura",
    svadhisthana: "2. Svadhishthana",
    muladhara: "1. Muladhara",
    total: "Total",
  },
  "texts-de": {
    chakras: "Chakren",
    physics: "Physik",
    energy: "Energie",
    emotions: "Emotionen",

    sahasrara: "7. Sahasrara",
    ajna: "6. Ajna",
    vishuddha: "5. Vishuddha",
    anahata: "4. Anahata",
    manipura: "3. Manipura",
    svadhisthana: "2. Svadhishthana",
    muladhara: "1. Muladhara",
    total: "Gesamt",
  },
  "texts-ru": {
    chakras: "Чакры",
    physics: "Физика",
    energy: "Энергия",
    emotions: "Эмоции",

    sahasrara: "7. Сахасрара",
    ajna: "6. Аджна",
    vishuddha: "5. Вишуддха",
    anahata: "4. Анахата",
    manipura: "3. Манипура",
    svadhisthana: "2. Свадхистхана",
    muladhara: "1. Муладхара",
    total: "Итого",
  }
}