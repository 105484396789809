import React, { useState, useEffect } from 'react'
import { checkDate } from '../utils/utils'
import { useLocale } from '../../../hooks/useLocale'
import { useAuth } from '../../../hooks/useAuth'
import InputMask from 'react-input-mask'
import { useDate } from '../hooks/useDate'
import { Modal } from '../../../components/common/Modal'
import '../../../styles/Modal.less'

const InputWithError = ({ value, onChange, error }) => {
  const { ls } = useLocale()

  return (
    <div className="modal-form-input-container">
      <InputMask
        mask="99.99.9999"
        maskPlaceholder="DD.MM.YYYY"
        alwaysShowMask={false}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={ls("dd.mm.yyyy", "TT.MM.JJJJ", "дд.мм.гггг")}
      />
      {error && <div className="modal-form-message error">{error}</div>}
    </div>
  )
}

export const DateDialog = ({ onClose, isOpen }) => {
  const { isLogin, isFullUser } = useAuth()
  const { ls } = useLocale()
  const {
    date1: dateInit,
    date2: dateInit2,
    setDate1: setDate1loc,
    setName1: setName1loc,
    name1: nameInit,
    name2: nameInit2,
    setDate2: setDate2loc,
    setName2: setName2loc,
    isDouble: doubleInit,
    setDouble: setDoubleloc
  } = useDate()

  const [date, setDate] = useState(dateInit)
  const [name, setName] = useState(nameInit)
  const [date2, setDate2] = useState(dateInit2)
  const [name2, setName2] = useState(nameInit2)
  const [double, setDouble] = useState(doubleInit)
  const [dateError, setDateError] = useState('')
  const [date2Error, setDate2Error] = useState('')

  useEffect(() => {
    if (!checkDate(date)) {
      setDateError(ls(
        "Invalid date. Expected format dd.mm.yyyy",
        "Ungültiges Datum. Erwartetes Format TT.MM.JJJJ",
        "Неверная дата. Ожидаемый формат дд.мм.гггг"
      ))
    } else {
      setDateError('')
    }
  }, [date])

  useEffect(() => {
    if (double && !checkDate(date2)) {
      setDate2Error(ls(
        "Invalid date. Expected format dd.mm.yyyy",
        "Ungültiges Datum. Erwartetes Format TT.MM.JJJJ",
        "Неверная дата. Ожидаемый формат дд.мм.гггг"
      ))
    } else {
      setDate2Error('')
    }
  }, [date2, double])

  const handleSave = () => {
    if (!dateError && (!double || !date2Error)) {
      setDate1loc(date)
      setDate2loc(date2)
      setName1loc(name)
      setName2loc(name2)
      setDoubleloc(double)
      onClose()
    }
  }

  const handleExchange = () => {
    const tempName = name2
    const tempDate = date2
    setName2(name)
    setDate2(date)
    setName(tempName)
    setDate(tempDate)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={ls("Edit Dates", "Daten bearbeiten", "Редактировать даты")}
      isModal={true}
    >
      <div className="modal-form-input-container">
        <label>
          {ls("Name", "Name", "Имя")}:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </div>

      <div className="modal-form-input-container">
        <label>
          {ls("Date", "Datum", "Дата")}:
          <InputWithError
            value={date}
            onChange={setDate}
            error={dateError}
          />
        </label>
      </div>

      {isLogin && isFullUser && (
        <div className="modal-form-input-container" style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '10px 0'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            minWidth: 'fit-content'
          }}>
            <input
              type="checkbox"
              id="second-date-checkbox"
              checked={double}
              onChange={() => setDouble(!double)}
              style={{
                width: '16px',
                height: '16px',
                margin: 0
              }}
            />
            <label
              htmlFor="second-date-checkbox"
              style={{ whiteSpace: 'nowrap' }}
            >
              {ls("second date", "zweites Date", "вторая дата")}
            </label>
          </div>

          {double && (
            <button
              className="button-clear-cache"
              onClick={handleExchange}
              title={ls(
                "Exchange dates and names",
                "Daten und Namen tauschen",
                "Обменять даты и имена"
              )}
              style={{
                padding: '4px 12px',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                marginLeft: 'auto'
              }}
            >
              {ls("⇅ Exchange", "⇅ Tauschen", "⇅ Обменять")}
            </button>
          )}
        </div>
      )}

      {double && isLogin && (
        <>
          <div className="modal-form-input-container">
            <label>
              {ls("Second Name", "Zweiter Name", "Второе имя")}:
              <input
                type="text"
                value={name2}
                onChange={(e) => setName2(e.target.value)}
              />
            </label>
          </div>

          <div className="modal-form-input-container">
            <label>
              {ls("Second Date", "Zweites Datum", "Вторая дата")}:
              <InputWithError
                value={date2}
                onChange={setDate2}
                error={date2Error}
              />
            </label>
          </div>
        </>
      )}

      <div className="modal-form-buttons">
        <button className="button-clear-cache" onClick={onClose}>
          {ls("Cancel", "Abbrechen", "Отмена")}
        </button>
        <button className="button-login" onClick={handleSave} disabled={dateError || (double && date2Error)}>
          {ls("Save", "Speichern", "Сохранить")}
        </button>
      </div>
    </Modal>
  )
}

