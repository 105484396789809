import React from 'react'
import { useDate } from '../hooks/useDate'
import { useModal } from '../../../hooks/useModal'
import { DateDialog } from './DateDialog'

export const EditDateButton = ({ first }) => {
  const { date1, date2 } = useDate()
  const { isOpen, open, close } = useModal()
  const date = first ? date1 : date2

  return (
    <div>
      <DateDialog isOpen={isOpen} onClose={close} />
      <div className="horiz-container">
        <input
          type="text"
          value={date}
          readOnly
          onClick={open}
          style={{
            width: '100px',
            cursor: 'pointer',
            border: '1px solid #ccc',
            marginRight: 0,
            borderRadius: '4px',
          }}
        />
        <button 
          className="coolButton" 
          type="button" 
          onClick={open} 
          style={{ marginLeft: 0 }}
        >
          ✏️
        </button>
      </div>
    </div>
  )
}