import React from "react"
import { useLocale } from "../../../hooks/useLocale"
import { UndefinedComponemt } from "./Components"
import { useDate } from "../hooks/useDate"
import { EditDateButton } from "./EditDateButton"
import { useAuth } from "../../../hooks/useAuth"

const ShowDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocale()
  return (
    <button onClick={() => setSingle(false)}>
      {ls("show second date >>", "zweites Datum anzeigen >>", "показать вторую дату >>")}
    </button>
  )
}

const HideDate2Button = () => {
  let { setSingle } = useDate()
  let { ls } = useLocale()
  return (
    <button onClick={() => setSingle(true)}>
      {ls("hide second date", "zweites Datum ausblenden", "спрятать вторую дату")}
    </button>
  )
}

const SwapDatesButton = () => {
  let { swapDate } = useDate()
  let { ls } = useLocale()
  return (
    <button style={{ marginRight: 20 }} onClick={() => swapDate()}>
      {ls("⇅ swap dates", "⇅ Daten tauschen", "⇅ поменять даты местами")}
    </button>
  )
}

const ReportButton = () => {
  let { ls } = useLocale()
  const clickHandler = () => {
    // docxReport({ _page: ls("Page", "Site", "Страница"), _of: ls("of", "von", "из"), first })
    window.print()
  }
  return (
    <button onClick={clickHandler}>
      {/* &#8659; .docx (ms-word) */}
      {ls("print", "druck", "печать")}
    </button>
  )
}

export const ButtonsBlock = ({ first, second }) => {
  let { isSingle, isDouble } = useDate()
  let { isFullUser } = useAuth()
  if (first)
    return (
      <div className="horiz-container">
        <EditDateButton first />
        {isSingle && isFullUser && <ShowDate2Button />}
        {isDouble && <HideDate2Button />}
        {isDouble && <SwapDatesButton />}
        <ReportButton />
      </div>
    )

  if (second)
    return (
      <div className="horiz-container">
        <EditDateButton second />
        {/* <ShowDate2Button /> */}
        <HideDate2Button />
        <SwapDatesButton />
        {/* <ReportButton /> */}
      </div>
    )

  return <UndefinedComponemt name="ButtonsBlock" />
}