import React from 'react'
import { useLocale } from '../../hooks/useLocale'
import PropTypes from 'prop-types'

export const ActivityHistogram = ({ historyData, selectedDate, onDateSelect }) => {
  const { ls } = useLocale()

  const prepareHistogramData = () => {
    const data = new Map()

    historyData.forEach(entry => {
      const date = entry.timestamp.split(' ')[0]
      const eventCount = (entry.event.login || 0) + (entry.event.update || 0)
      data.set(date, (data.get(date) || 0) + eventCount)
    })

    return Array.from(data.entries()).sort((a, b) => a[0].localeCompare(b[0]))
  }

  const data = prepareHistogramData()

  return (
    <div style={{ marginTop: '24px' }}>
      <h3>
        {ls("Activity Histogram", "Aktivitätshistogramm", "Гистограмма активности")}
        {selectedDate && ` (${ls("Selected", "Ausgewählt", "Выбрано")}: ${selectedDate})`}
      </h3>
      <div style={{
        display: 'flex',
        alignItems: 'flex-end',
        height: '100px',
        gap: '2px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}>
        {data.map(([date, count], index) => {
          const height = `${Math.max(20, (count / Math.max(...data.map(d => d[1]))) * 100)}%`
          return (
            <div
              key={date}
              onClick={() => onDateSelect(date)}
              style={{
                flex: 1,
                height,
                backgroundColor: date === selectedDate
                  ? `hsl(${40 + (index * 10) % 160}, 95%, 75%)`
                  : `hsl(${40 + (index * 10) % 160}, 85%, 85%)`,
                position: 'relative',
                minWidth: '20px',
                cursor: 'pointer',
              }}
              title={`${date}: ${count} ${ls("events", "Ereignisse", "событий")}`}
            >
              <div style={{
                position: 'absolute',
                bottom: '-20px',
                left: '50%',
                transform: 'translateX(-50%) rotate(-45deg)',
                fontSize: '10px',
                whiteSpace: 'nowrap'
              }}>
                {date}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ActivityHistogram.propTypes = {
  historyData: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      event: PropTypes.shape({
        login: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        update: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      }).isRequired
    })
  ).isRequired,
  selectedDate: PropTypes.string,
  onDateSelect: PropTypes.func.isRequired
} 