import React, { useState, useEffect } from 'react'
import { useLocale } from "../hooks/useLocale"
import { setupUpdateHandlers } from './updateManager'

export const UpdateBanner = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const [newVersion, setNewVersion] = useState(null)
  const [isUpdatePending, setIsUpdatePending] = useState(false)
  const { ls } = useLocale()

  // Initialize update handling
  useEffect(() => {
    if (!__DEBUG__) {
      return setupUpdateHandlers((version) => {
        setNewVersion(version)
        setUpdateAvailable(true)
      })
    }
  }, [])

  const handleUpdate = () => {
    if ('serviceWorker' in navigator) {
      window.location.reload(true)
    }
  }

  const handleDismiss = () => {
    setIsUpdatePending(true)
    setUpdateAvailable(false)
    setTimeout(() => {
      setUpdateAvailable(true)
    }, 5 * 60 * 1000) // 5 minutes
  }

  if (!updateAvailable) return null

  return (
    <div className="update-banner">
      <span>{isUpdatePending ? ls(
        "🔄 Reload page to update website",
        "🔄 Seite neu laden, um die Website zu aktualisieren",
        "🔄 Перезагрузите страницу для обновления сайта"
      ) : ls(
        `🔄 New website version ${newVersion} is available!`,
        `🔄 Neue Website-Version ${newVersion} ist verfügbar!`,
        `🔄 Доступна новая версия сайта ${newVersion}!`
      )}</span>
      <div className="banner-buttons">
        <button className="update-button" onClick={handleUpdate}>{ls(
          'Reload',
          'Neu laden',
          'Перезагрузить'
        )}</button>
        <button className="dismiss-button" onClick={handleDismiss}>{ls(
          'Later',
          'Später',
          'Позже'
        )}</button>
      </div>
    </div>
  )
}
