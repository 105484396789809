import React from 'react'
import '../../styles/Modal.less'

export const Modal = ({ 
  isOpen, 
  onClose, 
  title, 
  children,
  isModal = true // default modal
}) => {
  if (!isOpen) return null

  const handleOverlayClick = (e) => {
    if (!isModal && e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        {children}
      </div>
    </div>
  )
} 