import React from "react"
import { mod, checkTripletWithStyles } from "../../utils/utils"
import { tdWithHint } from "../Components"
import "../../../../styles/Table.less"


let s
export const setReport = (value) => s = value
export const getReport = () => s

export function rowC4S1_span(name, num1, num2, noShowTips) {
  s(name)
  let a = num1
  let b = num2
  let c = mod(num1 + num2)

  let [sa, sb, sc] = checkTripletWithStyles(a, b, c)

  s(sa)
  s(sb)
  s(sc)

  return (
    <tr>
      <td colSpan="2">{name}</td>
      {tdWithHint(a, sa, undefined, undefined, noShowTips)}
      {tdWithHint(b, sb, 2, undefined, noShowTips)}
      {tdWithHint(c, sc, undefined, undefined, noShowTips)}
    </tr>
  )
}

export function rowC4S1(name, num1, num2, noShowTips, titleStyle = null) {
  s(name)
  let a = num1
  let b = num2
  let c = mod(num1 + num2)

  let [sa, sb, sc] = checkTripletWithStyles(a, b, c)

  s(sa)
  s(sb)
  s(sc)

  return (
    <tr>
      <td style={titleStyle}>{name}</td>
      {/* <td style={titleStyle}>{name}</td> */}
      {tdWithHint(a, sa, undefined, undefined, noShowTips)}
      {tdWithHint(b, sb, undefined, undefined, noShowTips)}
      {tdWithHint(c, sc, undefined, undefined, noShowTips)}
    </tr>
  )
}

export function rowC4(name, num1, num2, num3, noShowTips) {
  s(name)
  let a = num1
  let b = num2
  let c = num3

  let [sa, sb, sc] = checkTripletWithStyles(a, b, c)

  s(sa)
  s(sb)
  s(sc)

  return (
    <tr >
      <td>{name}</td>
      {tdWithHint(a, sa, undefined, undefined, noShowTips)}
      {tdWithHint(b, sb, undefined, undefined, noShowTips)}
      {tdWithHint(c, sc, undefined, undefined, noShowTips)}
    </tr>
  )
}

export function headerC4(col1, col2, col3, col4) {
  return (
    <tr className="fat-border-bottom">
      <th>{s(col1)}</th>
      <th className="alighn-center">{s(col2)}</th>
      <th className="alighn-center">{s(col3)}</th>
      <th className="alighn-center">{s(col4)}</th>
    </tr>
  )
}

export function rowC2(name, value) {
  return (
    <tr >
      <td colSpan="2">{s(name)}</td>
      <td colSpan="4" className="alighn-center">{s(value)}</td>
    </tr>
  )
}

export function headerC2(col1, col2) {
  return (
    <tr className="fat-border-bottom">
      <th colSpan="2">{s(col1)}</th>
      <th colSpan="4" className="alighn-center">{s(col2)}</th>
    </tr>
  )
}

export function rowC4_span(name1, num1, name2, num2) {
  return (
    <tr >
      <td colSpan="2">{s(name1)}</td>
      <td className="alighn-center">{s(num1)}</td>
      <td colSpan="2" className="alighn-center">{s(name2)}</td>
      <td className="alighn-center">{s(num2)}</td>
    </tr>
  )
}