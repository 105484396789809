import React, { useState, useEffect, useMemo } from 'react'
import { useAuth } from '../../hooks/useAuth'
import config from '../../secret_config.json'
import { useLocale } from '../../hooks/useLocale'
import { ActivityHistogram } from './ActivityHistogram'

export const UserActivity = ({ userId }) => {
  const { jwt } = useAuth()
  const { ls } = useLocale()
  const [historyData, setHistoryData] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showUserAgent, setShowUserAgent] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')

  const [dateRange, setDateRange] = useState(() => {
    return localStorage.getItem('userActivityDateRange') || 'week'
  })

  const handleDateRangeChange = (e) => {
    const newValue = e.target.value
    setDateRange(newValue)
    localStorage.setItem('userActivityDateRange', newValue)
  }

  useEffect(() => {
    const fetchUserHistory = async () => {
      setLoading(true)
      try {
        let startDate, endDate
        const today = new Date()

        switch (dateRange) {
          case 'day':
            startDate = endDate = today.toISOString().split('T')[0]
            break
          case 'week':
            startDate = new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          case 'month':
            startDate = new Date(today.setMonth(today.getMonth() - 1)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          case 'year':
            startDate = new Date(today.setFullYear(today.getFullYear() - 1)).toISOString().split('T')[0]
            endDate = new Date().toISOString().split('T')[0]
            break
          default:
            startDate = endDate = today.toISOString().split('T')[0]
        }

        const response = await fetch(`${config.apiFolder}user_history.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt,
            userId,
            startDate,
            endDate,
          }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const responseData = await response.json()
        if (responseData.status === 'success') {
          setHistoryData(responseData.history)
        } else {
          setMessage(responseData.message)
        }
      } catch (error) {
        setMessage('Failed to fetch user history: ' + error.message)
      } finally {
        setLoading(false)
      }
    }

    if (jwt && userId) {
      fetchUserHistory()
    }
  }, [jwt, userId, dateRange])

  const getDeviceColor = (device, os, browser) => {
    const hash = `${device}-${os}-${browser}`.split('').reduce(
      (acc, char) => char.charCodeAt(0) + ((acc << 4) - acc), 0
    )
    const hue = 40 + (hash % 160)
    return `hsl(${hue}, 85%, 85%)`
  }

  const filteredHistoryData = selectedDate
    ? historyData.filter(entry => entry.timestamp.split(' ')[0] === selectedDate)
    : historyData

  const handleBarClick = (date) => {
    setSelectedDate(date)
  }

  const handleResetFilter = () => {
    setSelectedDate(null)
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const processedData = useMemo(() => {
    let result = [...filteredHistoryData]
    
    if (sortField) {
      result.sort((a, b) => {
        const isEventField = ['login', 'update', 'REMOTE_ADDR', 'app_version', 'HTTP_USER_AGENT'].includes(sortField)
        
        let aValue = isEventField ? (a.event[sortField] || '') : a[sortField]
        let bValue = isEventField ? (b.event[sortField] || '') : b[sortField]
        
        if (sortField === 'login' || sortField === 'update') {
          aValue = Number(aValue) || 0
          bValue = Number(bValue) || 0
        }
        
        const compareResult = sortDirection === 'asc' ? 1 : -1
        return aValue > bValue ? compareResult : -compareResult
      })
    }
    
    return result
  }, [filteredHistoryData, sortField, sortDirection])

  return (
    <div>
      <h2>{ls("User Activity", "Benutzeraktivität", "Активность пользователя")}</h2>
      {message && <p>{message}</p>}

      {loading ? (
        <p>{ls("Loading...", "Wird geladen...", "Загрузка...")}</p>
      ) : (
        <>
          <div style={{ 
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              {!selectedDate && (
                <label>
                  {ls("Select Date Range:", "Datumsbereich auswählen:", "Выберите диапазон дат:")}
                  <select value={dateRange} onChange={handleDateRangeChange}>
                    <option value="day">{ls("Today", "Heute", "Сегодня")}</option>
                    <option value="week">{ls("Last Week", "Letzte Woche", "Последняя неделя")}</option>
                    <option value="month">{ls("Last Month", "Letzter Monat", "Последний месяц")}</option>
                    <option value="year">{ls("Last Year", "Letztes Jahr", "Последний год")}</option>
                  </select>
                </label>
              )}
              {selectedDate && (
                <div style={{ display: 'inline-block' }}>
                  <button
                    onClick={handleResetFilter}
                    style={{ marginLeft: '16px' }}
                  >
                    {ls("Reset Date Filter", "Datumsfilter zurücksetzen", "Сбросить фильтр даты")}
                  </button>
                  <span style={{ marginLeft: '8px' }}>
                    ({ls("Selected", "Ausgewählt", "Выбрано")}: {selectedDate})
                  </span>
                </div>
              )}
            </div>
            <label>
              <input
                type="checkbox"
                checked={showUserAgent}
                onChange={(e) => setShowUserAgent(e.target.checked)}
              />
              {ls("Show User Agent", "User Agent anzeigen", "Показать User Agent")}
            </label>
          </div>

          <table border="1" style={{ marginTop: '16px', width: '100%' }} className="sortable-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('timestamp')} style={{ cursor: 'pointer' }}>
                  {ls("Timestamp", "Zeitstempel", "Временная метка")}
                  {sortField === 'timestamp' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                {showUserAgent && (
                  <th onClick={() => handleSort('HTTP_USER_AGENT')} style={{ cursor: 'pointer' }}>
                    {ls("HTTP User Agent", "HTTP User Agent", "HTTP User Agent")}
                    {sortField === 'HTTP_USER_AGENT' && (
                      <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                    )}
                  </th>
                )}
                <th onClick={() => handleSort('device')} style={{ cursor: 'pointer' }}>
                  {ls("Device", "Gerät", "Устройство")}
                  {sortField === 'device' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('os')} style={{ cursor: 'pointer' }}>
                  OS
                  {sortField === 'os' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('browser')} style={{ cursor: 'pointer' }}>
                  Browser
                  {sortField === 'browser' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('REMOTE_ADDR')} style={{ cursor: 'pointer' }}>
                  Remote Address
                  {sortField === 'REMOTE_ADDR' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('app_version')} style={{ cursor: 'pointer' }}>
                  App Version
                  {sortField === 'app_version' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('login')} style={{ cursor: 'pointer' }}>
                  Logins
                  {sortField === 'login' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('update')} style={{ cursor: 'pointer' }}>
                  Updates
                  {sortField === 'update' && (
                    <span>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {processedData.length > 0 ? (
                processedData.map((entry, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: getDeviceColor(entry.device, entry.os, entry.browser)
                    }}
                  >
                    <td>{entry.timestamp}</td>
                    {showUserAgent && <td>{entry.event.HTTP_USER_AGENT}</td>}
                    <td>{entry.device}</td>
                    <td>{entry.os}</td>
                    <td>{entry.browser}</td>
                    <td>{entry.event.REMOTE_ADDR}</td>
                    <td>{entry.event.app_version || 'unknown'}</td>
                    <td>{entry.event.login || 0}</td>
                    <td>{entry.event.update || 0}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={showUserAgent ? "9" : "8"}>
                    {ls("No activity found for the selected range.", 
                        "Keine Aktivitäten im ausgewählten Zeitraum gefunden.", 
                        "Активность за выбранный период не найдена.")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {historyData.length > 0 && (
            <ActivityHistogram 
              historyData={historyData}
              selectedDate={selectedDate}
              onDateSelect={handleBarClick}
            />
          )}
        </>
      )}
    </div>
  )
}