import React, { useEffect, useState, useCallback } from 'react'
import { cn } from '../../../utils/classnames'
import { programNames } from '../constants/programsList'

export const UndefinedComponemt = ({ name }) => <h1>{name} is undefined (required prop first or second or third)</h1>

export function CollectNames(PIds) {
  return programNames.filter(d => PIds.includes(d.PId)).map((d, idx) => <ProgramInfo key={idx} programm={d} />)
}

const ProgramInfo = ({ programm }) => {

  let [pos, setPos] = useState(0)
  let PId = programm.PId

  const tick = useCallback((pId, newPos) => {
    if (PId === pId) setPos(newPos)
  }, [PId])

  useEffect(() => {
    AC.registryTick(PId, tick)
    // return () => AC.deleteTick(PId)  // stop playing if hint is closed
  }, [PId, tick])

  function onPosChange(e) {
    AC.setPosition(PId, e.target.value)
  }

  return <><div style={{ color: programm.isKarmicTail ? "red" : "blue" }}>{programm.fullName}</div>
    <button onClick={() => AC.play(programm, tick)} style={{ padding: 0, fontSize: "large" }}>&#x23ef;</button>
    <button onClick={() => AC.stop()} style={{ padding: 0, fontSize: "large" }}>&#x23f9;</button>
    <input type="range" min="1" max="100" onChange={onPosChange} value={pos}></input>
  </>
}

export function spanWithHint(sa, noShowTips) {
  return !noShowTips && sa.names && sa.names.length > 0 && <span className="CellComment">{sa.names}</span>
}

export function tdWithHint(text, sa, span, className, noShowTips) {
  return (
    <td className={cn({ "CellWithComment": true, "highlight": !!sa.style })} colSpan={span} style={{ textAlign: "center" }}>{text}
      {spanWithHint(sa, noShowTips)}
    </td>
  )
}

class AC {

  static registryTick(PId, onPosTick) {
    AC.ticks[PId] = onPosTick
  }

  static deleteTick(PId) {
    if (AC.pId === PId) AC.stop()
    delete AC.ticks[PId]
  }

  static play(programm, tick) {
    if (tick) AC.registryTick(programm.PId, tick)
    if (!AC.tickRunning) AC._tick_()
    let src = `sounds/${programm.fullName}.m4a`

    if (AC.pId !== programm.PId) {
      AC.pId = programm.PId
      AC.player.src = src
      AC.player.play()
    } else {
      if (!AC.player.paused)
        AC.player.pause()
      else AC.player.play()
    }

  }

  static stop() {
    AC.player.currentTime = 0
    AC.player.pause()
  }

  static setPosition(PId, pos) {
    if (PId === AC.pId) AC.player.currentTime = pos * AC.player.duration / 100
  }

  static _tick_() {
    let tick = AC.ticks[AC.pId]
    if (tick && !AC.player.paused) {
      tick(AC.pId, AC.player.currentTime / AC.player.duration * 100)
    }
    setTimeout(AC._tick_, 500)
    if (!AC.tickRunning) AC.tickRunning = true
  }
}

AC.tickRunning = undefined
AC.player = new Audio()
AC.pId = undefined
AC.ticks = []