import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import config from '../secret_config.json'
import { useVersion } from '../hooks/useVersion'

export const AuthContext = createContext({})

// decoding JWT token
const decodeJwt = (token) => {
  if (!token) return null
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    if (__DEBUG__) console.log("decoded JWT : ", jsonPayload)
    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}

// decoding status
const decodeStatus = (status) => {
  if (status == null || status == undefined) return {}

  let isSuperAdmin = !!(status & (1 << 7))
  let isAdmin = !!(status & (1 << 6))
  let isLogin = true
  let isBlocked = !!(status & 1)
  let isFullUser = !!(status & 2) || isAdmin || isSuperAdmin

  let result = {
    isLogin,
    isSuperAdmin,
    isAdmin,
    isBlocked,
    isFullUser
  }

  if (__DEBUG__) console.log("decoded status : ", result)

  return result
}

export const AuthProvider = ({ children }) => {
  AuthProvider.propTypes = { children: PropTypes.node.isRequired }

  const [data, setData] = useState({})
  const { version } = useVersion()

  const login = (jwtToken) => {
    const decoded = decodeJwt(jwtToken)

    if (decoded) {
      setData({ ...decoded, ...decodeStatus(decoded.status), jwt: jwtToken })
      localStorage.setItem('jwt', jwtToken)
    }
  }

  const StatusToText = ({ status }) => {
    let _isBlocked = !!(status & 1)
    let _isSuperAdmin = !!(status & (1 << 7))
    let _isAdmin = !!(status & (1 << 6))

    let user = (status & 2) ? <div>full&#8209;user</div> : <div>limit&#8209;user</div>
    
    let blocked = _isBlocked ? <span style={{ color: "red" }}>blocked</span> : "";
    
    if (_isSuperAdmin && data.isSuperAdmin) return <div><span style={{ color: "green" }}><b>super&#8209;admin</b></span> {blocked}</div>
    if (_isSuperAdmin || _isAdmin) return <div><span style={{ color: "green" }}><b>admin</b></span> {blocked}</div>
    
    return <div>{user} {blocked}</div>
  }

  const logout = () => {
    setData({})
    localStorage.removeItem('jwt') // delete not valid jwt
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('jwt')
    login(storedToken)
    if (storedToken) {
      const updateJwt = async () => {
        try {
          const response = await fetch(`${config.apiFolder}update.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              jwt: storedToken,
              version: version
            }),
          })

          // If server responded, check the response
          const responseData = await response.json()
          if (responseData.status === 'success' && responseData.jwt) {
            login(responseData.jwt)
          } else {
            // Server responded that token is invalid
            logout()
          }
        } catch (error) {
          console.error('Failed to update JWT:', error)
          // Only keep current state if it's a network error
          if (!navigator.onLine || 
              error.name === 'TypeError' || // Failed to fetch
              error instanceof TypeError) { // Network error
            // Keep current state - probably offline
            console.log('Network error - keeping current auth state')
            login(storedToken)
          } else {
            // Other errors - logout
            logout()
          }
        }
      }
      console.log('Start update JWT:')
      updateJwt()
    }
  }, [])

  const contextValue = { ...data, login, logout, StatusToText }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}


