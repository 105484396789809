import React, { useState, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'
import config from '../../secret_config.json'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocale } from '../../hooks/useLocale'
import { UserActivity } from './UserActivity'

export const UserEditPage = () => {
  const { userId } = useParams()
  const { ls } = useLocale()
  const [userData, setUserData] = useState({})
  const [message, setMessage] = useState('')
  const { jwt, isAdmin, isSuperAdmin, StatusToText } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (jwt && (isAdmin || isSuperAdmin)) fetchUserData()
  }, [jwt, userId, isAdmin, isSuperAdmin])

  const fetchUserData = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${config.apiFolder}get_users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          userId,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      if (responseData.status === 'success') {
        let user = responseData.users.filter(i => i.user_id === userId)
        setUserData(user[0])
      } else {
        setMessage(responseData.message)
      }
    } catch (error) {
      setMessage('Failed to fetch user data: ' + error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSendNewStatus = async (newStatus) => {
    try {
      const response = await fetch(`${config.apiFolder}user_update.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          user_id: userId,
          status: newStatus,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      if (responseData.status === 'success') {
        setUserData({ ...userData, status: newStatus })
        setMessage('User updated successfully!')
      } else {
        setMessage(responseData.message)
      }
    } catch (error) {
      setMessage('Failed to update user: ' + error.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData({ ...userData, [name]: value })
  }

  const handleClockUnlockClick = async () => {
    if (window.confirm(
      (userData.status & 1)
        ? ls(`Are you sure you want to unlock the user ${userData.name}?`, `Möchten Sie den Benutzer ${userData.name} wirklich entsperren?`, `Вы уверены, что хотите разблокировать пользователя ${userData.name}?`)
        : ls(`Are you sure you want to block the user ${userData.name}?`, `Möchten Sie den Benutzer ${userData.name} wirklich sperren?`, `Вы уверены, что хотите заблокировать пользователя ${userData.name}?`)
    )) {
      await handleSendNewStatus(userData.status & 1 ? userData.status & ~1 : userData.status | 1)
    }
  }

  const handleLimitUserClick = async () => {
    if (window.confirm(
      (userData.status & 2)
        ? ls("Are you sure you want to set the user as a limit-user?", "Möchten Sie den Benutzer wirklich als Limit-Benutzer festlegen?", "Вы уверены, что хотите сделать пользователя limit-user?")
        : ls("Are you sure you want to set the user as a full-user?", "Möchten Sie den Benutzer wirklich als Voll-Benutzer festlegen?", "Вы уверены, что хотите сделать пользователя full-user?")
    )) {
      await handleSendNewStatus(userData.status & 2 ? userData.status & ~2 : userData.status | 2)
    }
  }

  if (!isAdmin && !isSuperAdmin) return <p>No admin permissions</p>

  return (
    <div>
      <br />
      <button onClick={() => navigate(-1)}>{ls("Back", "Zurück", "Назад")}</button>
      <h1>{ls("Edit User", "Benutzer bearbeiten", "Редактирование пользователя")}</h1>
      {loading && <p>Loading...</p>}
      {!loading && <>
        {message && <p>{message}</p>}
        <form style={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '300px' }}>

          {isSuperAdmin && <label>
            Id:
            <input type="text" name="name" value={userData.user_id || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
          </label>
          }

          <label>
            {ls("Name", "Name", "Имя")}:
            <input type="text" name="name" value={userData.name || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
          </label>

          {userData.fullname && <label>
            {ls("Fullname", "Vollständiger Name", "Полное имя")}:
            <input type="text" name="fullname" value={userData.fullname || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
          </label>
          }

          <label>
            {ls("Email", "E-Mail", "Электронная почта")}:
            <input type="email" name="email" value={userData.email || ''} onChange={handleChange} readOnly style={{ width: '100%' }} />
          </label>

          <label>
            {ls("Status", "Status", "Статус")}:
            <div className='div-as-input' style={{ width: '100%' }}><StatusToText status={userData.status} /></div>
          </label>

          <button type="button"
            onClick={handleLimitUserClick} style={{ alignSelf: 'flex-start' }} disabled={userData.status & (3 << 6)}>
            {(userData.status & 2)
              ? ls("Set to limit-user", "Als Limit-Benutzer festlegen", "Сделать limit-user")
              : ls("Set to full-user", "Als Voll-Benutzer festlegen", "Сделать full-user")}
          </button>

          <button type="button"
            onClick={handleClockUnlockClick} style={{ alignSelf: 'flex-start' }} disabled={userData.status & (3 << 6)}>
            {(userData.status & 1)
              ? ls("Unlock user", "Benutzer entsperren", "Разблокировать пользователя")
              : ls("Block user", "Benutzer sperren", "Заблокировать пользователя")}
          </button>
        </form>
      </>}

      {isAdmin && <UserActivity userId={userId} />}
    </div>
  )
}
