import React from "react"
import { dateParse, solve, mod, checkTripletWithStyles } from "../../utils/utils"
import { addToSerializeTabFirst, addToSerializeTabSecond } from "../../utils/storage"
import "../../../../styles/Table.less"

import { tdWithHint, UndefinedComponemt } from "../Components"
import { useDate } from "../../hooks/useDate"
import { useLocale } from "../../../../hooks/useLocale"
import { useAuth } from "../../../../hooks/useAuth"

let s

export const YearlySummaryTable = ({ first, second }) => {
  let { isFullUser } = useAuth()
  let { date1, date2 } = useDate()

  let { ls } = useLocale()

  let date = first ? date1 : second ? date2 : undefined
  if (!date) return <UndefinedComponemt name="YearlySummaryTable" />

  s = first ? addToSerializeTabFirst : addToSerializeTabSecond

  let { d: dd, m: mm, y: yy } = dateParse(date)
  let { a, b, c, d, a1, b1, c1, d1, years1, years2, years3, years4, years5, years6, years7, years8 } = solve(dd, mm, yy)

  let ring = [a, ...years1, a1, ...years2, b, ...years3, b1, ...years4, c, ...years5, c1, ...years6, d, ...years7, d1, ...years8]

  function getPeriod(indx) {
    let a = indx * 5 / 4
    let a1 = Math.floor(a)
    let a2 = Math.ceil(a)
    if (a1 === a2) return a
    return a1 + "-" + a2
  }

  let textHeader = ls("year", "Jahr", "лет")

  function header() {
    return (
      <tr className="fat-border">
        <th >{s(textHeader)}</th>
        <th colSpan="3" className="fat-border-right">{s("a+b=c")}</th>
        <th >{s(textHeader)}</th>
        <th colSpan="3" className="fat-border-right">{s("a+b=c")}</th>
      </tr>)
  }

  let tabCol = ring.map((d, indx) => {
    let a = d
    let b = ring[(indx + 32) & 0x3f]
    let c = mod(d + ring[(indx + 32) & 0x3f])

    let [sa, sb, sc] = checkTripletWithStyles(a, b, c)

    return (
      <>
        <td className="text-gray"><i>{s(getPeriod(indx))}</i></td>
        {tdWithHint(s(sa), sa, undefined, undefined, false)}
        {tdWithHint(s(sb), sb, undefined, undefined, false)}
        {tdWithHint(s(sc), sc, undefined, "fat-border-right", false)}
      </>)
  })

  return (
    <div className="horiz-container">
      <table>
        <tbody>
          {header()}
          {tabCol.map((d, indx) => indx < 16 && <tr key={indx}>
            {d}
            {tabCol[indx + 16]}
          </tr>)}
        </tbody>
      </table>

      <table>
        <tbody>
          {header()}
          {tabCol.map((d, indx) => indx >= 32 && indx < 48 && <tr key={indx}>
            {d}
            {tabCol[indx + 16]}
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}
