import React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import { Navigate } from 'react-router-dom'
import { Invite } from '../../features/admin/Invite'
import { GetUsers } from '../../features/admin/GetUsers'

export const AdminPage = () => {
  const { isAdmin, isSuperAdmin } = useAuth()
  const { ls } = useLocale()

  if (!isAdmin && !isSuperAdmin) return <Navigate to="/" />

  return (
    <div>
      <h1>{ls("Admin page", "Admin-Seite", "Страница администратора")}</h1>
      <Invite />
      <GetUsers />
    </div>
  )
}
