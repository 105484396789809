﻿import './styles/index.less'

if (__DEBUG__) {
  new EventSource('/esbuild').addEventListener('change', e => {

    let { added, removed, updated } = JSON.parse(e.data)

    if (!added.length && !removed.length && updated.length === 1) {
      for (let link of document.getElementsByTagName("link")) {
        let url = new URL(link.href)

        if (url.host === location.host && url.pathname === updated[0]) {
          let next = link.cloneNode()
          next.href = updated[0] + '?' + Math.random().toString(36).slice(2)
          next.onload = () => link.remove()
          link.parentNode.insertBefore(next, link.nextSibling)
          return
        }
      }
    }

    location.reload()
  })
}

if (!console.table) {
  console.table = () => { }
}